<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Vasion Analytics</h1>
    </div>
    <div class="vap-page-content">
      <div class="opt-in-switch-container">
        <div class="opt-in-switch">
          <md-switch v-model="isAnalyticsOptIn" @change="changeisAnalyticsOptOutSettings()"> 
            <span v-if="!isAnalyticsOptIn" class="text-muted">Opted out</span>
            <span v-if="isAnalyticsOptIn" class="text-muted">Opted in</span>
          </md-switch>
        </div>
      </div>
      <div class="opt-in-message">
        <span>For more information, please see our <a href="https://vasion.com/cookie-information/" target="_blank" class="link">Cookie</a> and <a href="https://vasion.com/privacy-policy/" target="_blank" class="link"><span>Privacy</span></a> Policy.</span>
      </div>
    </div>
    <VasionSnackbar
      id="profile-privacy-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'ProfilePrivacy',
  components: {
    Loading,
  },
  data: function () {
    return {
      isAnalyticsOptIn: true,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  created: async function () {
    await this.$store.dispatch('admin/getUserSetting', 'IsAnalyticsOptOut')
    this.isAnalyticsOptIn = !(this.$store.state.admin.originalIsAnalyticsOptOut === "true")
  },  
  methods: {
    async changeisAnalyticsOptOutSettings() {
      this.isLoading = true
      this.$store.dispatch('admin/isAnalyticsOptOutSelection', !this.isAnalyticsOptIn)

      const response = await this.$store.dispatch('admin/setUserSetting', { settingName: 'IsAnalyticsOptOut', settingValue: !this.isAnalyticsOptIn})

      if (response && response?.data) {
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = "Settings saved"
        this.snackbarImage = true
        this.showSnackbarBool = true
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = response?.data?.ResultMessage ? response?.data?.ResultMessage : 'Unable to save your preference.'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
      
      if (this.isAnalyticsOptIn) {
        const userId = this.$store.state.common.userID
        await this.$amplitude.checkAmplitudeAvailability()
        await this.$amplitude.identifyUser(userId > 0 ? `${window.location.host}:${userId}`: null)
      } else {
        await this.$amplitude.reset()
      }

      setTimeout(() => { this.isLoading = false }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/light-theme-system-palette.scss';

.opt-in-switch {
  font-size: 16px;
}

.opt-in-message {
  font-size: 14px;
}

::v-deep .md-switch.md-theme-default.md-checked .md-switch-container {
  background-color: $bg-primary-38;
}

::v-deep .md-switch.md-theme-default.md-checked .md-switch-thumb {
  background-color: $primary;
}
</style>